import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, TextField } from "@mui/material";

import settings from "../../misc/constants";

import Arrow from "../../img/arrow.webp";
import RightImg from "../../img/1.webp";
import CITLogo from "../../img/exam/new_logo.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box } from "@mui/system";

const HomeSliderForm = ({ page, landing }) => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    let data = {
      action: "book_demo",
      name: userName,
      email: userEmail,
      phone: userPhone,
      webinar_name: settings.webinar_date,
      // type: "slider",
      type: page,
    };

    fetch(`${settings.api_url}/api/book_demo.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          toast.success(data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          sessionStorage.setItem("userEmail", userEmail);

          navigate("/thank-you");
        } else {
          toast.error(data.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  return (
    <div
      className={`home-top-section bottom ${
        landing ? "landing-page-color" : ""
      } ${page === "landing" ? "landing-page-bottom" : ""}`}
    >
      <div className="left">
        {page !== "home" ? (
          <>
            <h2>
              <strong>
                JOIN US LIVE <span className="live"></span>
              </strong>
            </h2>
            <p>
              Enter your details and register for <br />
              <strong
                style={{
                  fontSize: "1.3rem",
                }}
              >
                "
                {!landing
                  ? settings.webinar_name
                  : "Digital Marketing Demo Class"}
                "
              </strong>
              .
            </p>
          </>
        ) : (
          <>
            <Box
              sx={{
                margin: {
                  xs: "0 auto",
                  md: "0",
                },
              }}
            >
              <LazyLoadImage
                src={CITLogo}
                alt="CITLogo"
                width="200px"
                height="auto"
              />
            </Box>
            <p>A path to your internship is here.</p>
            <strong
              style={{
                fontSize: "1.3rem",
              }}
            >
              Register for CIT'24 &amp; get a guaranteed internships in various
              domains.
            </strong>
          </>
        )}

        {page !== "home" ? (
          <form
            className={page === "landing" ? "input-landing" : "input"}
            onSubmit={handleSubmit}
          >
            {page === "landing" && (
              <div id="name">
                <TextField
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  type="name"
                  InputProps={{
                    style: { backgroundColor: "white" },
                  }}
                  label="Your Name"
                  variant="outlined"
                />
              </div>
            )}

            <div id="email">
              <TextField
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                type="email"
                InputProps={{
                  style: { backgroundColor: "white" },
                }}
                label="Your Email"
                variant="outlined"
              />
            </div>

            <div id="code">
              <TextField
                type="text"
                InputProps={{
                  style: { backgroundColor: "white" },
                }}
                variant="outlined"
                value="+91"
                disabled
              />
            </div>

            <div id="phone">
              <TextField
                value={userPhone}
                onChange={(e) => setUserPhone(e.target.value)}
                type="tel"
                label="Your Mobile Number"
                variant="outlined"
                InputProps={{
                  style: { backgroundColor: "white" },
                }}
              />
            </div>

            <div id="freeDemo">
              <Button
                className="primaryBackground regNowSliderBtn"
                variant="contained"
                type="submit"
                sx={{
                  color: "#fff",
                  height: "55px",
                }}
              >
                REGISTER NOW
              </Button>
            </div>
          </form>
        ) : (
          <Button
            variant="contained"
            className="primaryBackground"
            component={Link}
            to="/common-internship-test"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "60%",
                lg: "40%",
                xl: "30%",
              },
            }}
          >
            View Details
          </Button>
        )}
      </div>
      {!landing ? (
        <div className="right">
          <img src={Arrow} alt="arrow" />
        </div>
      ) : (
        <div className="right-img">
          <LazyLoadImage
            src={RightImg}
            alt="Student"
            height="100%"
            width="100%"
          />
        </div>
      )}
    </div>
  );
};

export default HomeSliderForm;
